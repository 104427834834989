import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Box, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("AI - AIについて ｜ VISUALIZ INC")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const size = useWindowSize(500);
    return (
        <main>
            <PageSummary
                title={t("AIについて")}
                subTitle={t("About AI business")}
                description={<>
                    {t("可能性は未知数で無限大。")} <br />
                    {t("プロジェクトのスタートにあったものはビジョンだけ。")}<br />
                    {t("スタートから１年、ゴールが見えてきたAIプロジェクトの全貌について。")}
                </>}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/AI_top.jpg").default}
            />

            <Box
                sx={{
                    background: theme.palette.background.default,
                }}
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("AIについて−１")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("大体の方がAIと聞いて、抱くイメージは大きく二つに別れると思う。")}
                    </SectionText1>
                    <SectionText1>
                        {t("どちらもステレオタイプに近いのだけど、例えば監視社会と人の生活を奪うというネガティブな類のイメージ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それから人はこれまでと変わらない生活を続けながらより便利に幸せになって行くというポジティブなイメージ。")}
                    </SectionText1>
                    <SectionText1>
                        {t("ゼロサムの結果などある訳がなく、例えばインターネットの爆発で大きく変わった生活の恩恵の陰で、ネガティブな部分も多くそれはそれで事の善悪の問題などである訳なく、かく言う僕自身もステレオタイプの中に未だ身を置いている一人かもしれない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("とは言え、AIは僕らの生活の深い場所で、知らない内に使われている道具にこそAIは浸透している訳で、スマホみたいな「いつもある道具」としてそれがAIかどうかなんてどうでも良く、いつの間にか欠かせないものになって行くのだろうと思う。")}
                    </SectionText1>
                    <SectionText1>
                        {t("とにかくタイトルにある2017年10月、僕はコンテンツとしてのAIを思い付き、どうすればそれを実現出来るのか考える事に夢中になり始めた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それも抱えている仕事をそっちのけで。")}
                    </SectionText1>
                    <SectionText1>
                        {t("いつもの癖で熱を帯びてくると、周りのスタッフを巻き込んで、文献をやたらと買い込み、出来る人員を探し始めた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("Pytohnは当たり前、Linuxのビルド、C、そしてTensorFlowを始めとするAIの見地を持ち、GitHubを使いこなし、GCPの構築まで可能な人物。")}
                    </SectionText1>
                    <SectionText1>
                        {t("いる訳がない。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そう思って、それでもダメ元で当時大学院生だった今の担当者に下図のアイデアを言ってみた。")}
                    </SectionText1>
                    <SectionText1>
                        {t("この流れにより、情報系サイトを短期間で構築し、低コストで運用出来るよね。")}
                    </SectionText1>
                    <SectionText1>
                        {t("極端に言うとYahoo!くらいの情報量を持つサイトが数人のパートさんで創れたら凄いよね、てな事を言った記憶がある。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("AIについて−2")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("面白そうですね。電話の向こうで事も無げに、それでもいつもより弾んだ声で彼は反応した。実際、僕の知る限り、候補として挙げる事の出来る唯一の人物でもあったのだが、まさか大学院でAIを専攻していたとは。")}
                    </SectionText1>
                    <SectionText1>
                        {t("流れが来ている。")}
                    </SectionText1>
                    <SectionText1>
                        {t("そう感じた僕はそこから毎日のように彼と打ち合わせをし、内容を決めていき、そして手探りのまま経験のないプロジェクトがスタートした。")}
                    </SectionText1>

                    <SectionText1>
                        {t("後は最終的なアウトプットをどうするか。教師用データが豊富にあり、市場性が高く、誰かの便利につながるコンテンツは、なんだろう。")}
                    </SectionText1>
                    <SectionText1>
                        {t("まず最初のサービスに選んだのが、全ての自治体・行政が行っている入札・プロポーザルに関する情報と新設法人に関する情報、後に助成金・補助金の情報を収集し、士業専門にこれらの情報を公開する、と言うものだった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("ターゲットを士業にしたのは幾つかの理由がある。")}
                    </SectionText1>
                    <SectionText1>
                        {t("もちろんこの情報提供だけで終わるつもりはなく、先々の戦略を見据えての事だが、まずは人と会うことが多い仕業の方々により多くの人への情報インフルエンサーになって頂きたいと言う思い。")}
                    </SectionText1>
                    <SectionText1>
                        {t("それにより情報弱者を減らしたいという思いもある。")}
                    </SectionText1>
                    <SectionText1>
                        {t("またAIの登場で苦戦が予想される士業市場をAIがフォローする構図を作ったら面白いとの側面もあった。マーケティング的に考えると、会社のステータス向上とロケットスタートを同時にしたいという皮算用も白状する。")}
                    </SectionText1>
                    <SectionText1>
                        {t("2018年5月")}
                    </SectionText1>
                    <SectionText1>
                        {t("教師あり学習の結果が得られました。")}
                    </SectionText1>
                    <SectionText1>
                        {t("思い通りの結果が出たので、使えそうです。弾んだ声に僕たちの心は踊った。")}
                    </SectionText1>
                    <SectionText1>
                        {t("ここに至るまでに決めた事は、どこのクラウドを使うか、どのAIを使用するか、DBは何を使うか、日本語の解析は何を使うか、と言った環境からパーセプトロンは何層にするかと言う具体的な部分まで仕様を繰り返し考えた。そして全ての環境を構築し、システムをビルド、必要な文献とシステムを揃え、開発と向き合った。")}
                    </SectionText1>
                    <SectionText1>
                        {t("体調を考慮し、在宅を始めとした様々な業務形態での開発を約束した僕らはそこからまず特許申請を視野に入れ、システムフローチャートと開発のマネジメント、そして仕様書の確定に力点を置く事にした。それは僕たちの開発の弱点を補う、言わばリスクヘッジを兼ねた戦術だった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("ともあれ、ここからの数ヶ月後、このプロジェクト最大の危機が訪れるとは思いもせず、教師あり学習を経て教師なし学習と強化学習へと歩みを進めていく事になった。")}
                    </SectionText1>
                    <SectionText1>
                        {t("その中枢を担うのがAIを使ったハッシュを使った日本語の解析システムである。ワークフローに応じ、使用するAIを使い分けながら、情報の合否判定・URLの取得・ノイズの除去・DBへの格納といったプロセスを自動化。この一連の部分を我々ヴィジュアライズは平成30年10月、先行技術調査を経て特許出願を行った。")}
                    </SectionText1>
                    <SectionText1>
                        {t("現在主幹弁理士としてみなとみらい特許事務所が当知財に関する全ての窓口業務を担っている。（2018年1月現在）")}
                    </SectionText1>
                </Box>
            </Box>
        </main >
    );
};
